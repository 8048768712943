<template>
  <v-container>
    <div id="flights" v-if="user && flights">
      <v-card class="elevation-0">
        <v-card-title>
          <v-row class="justify-space-between">
            <v-col cols="6">
              <v-text-field
                class="mr-6"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          class="table-cursor"
          :headers="headers"
          :items="flights"
          :search="search"
          dense
        ></v-data-table>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Flights",
  data() {
    return {
      search: "",
      headers: [
        { text: "Id", value: "_id" },
        { text: "Flight", value: "flight" },
        { text: "Time", value: "timeStamp" },
        { text: "Airport", value: "airportFull" },
        { text: "Code", value: "airportShort" },
      ],
    };
  },
  mounted() {
    this.loadFlights();
  },
  methods: {
    ...mapActions(["loadFlights"]),
  },
  computed: {
    ...mapState(["loaded", "user", "token", "flights"]),
  },
};
</script>
